@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: linear-gradient(
        to right top,
        #d16ba5,
        #bf69ae,
        #aa69b6,
        #9169bb,
        #736abe,
        #5c76c8,
        #3e80cf,
        #008ad2,
        #00a0db,
        #00b5dc,
        #00c8d8,
        #41dad1
    );
    height: 100%;
    margin: 0;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
